import React, { useState } from "react"

export default function Redirect() {
  const [remainingTime, setRemainingTime] = useState(10)

  setInterval(() => {
    if (remainingTime > 0) {
      setRemainingTime(remainingTime - 1)
    }
    else {
      window.location.href = "https://kartgames.ch"
    }
  }, 1000)

  return (
    <>
      <div className="bg-white-caption text-dark">
        Weiterleitung in {remainingTime} s
      </div>
    </>
  )
}
