import React from "react"
import map from "../assets/img/map-bg.png"
import { OutboundLink } from "gatsby-plugin-gtag"

export default function Contact() {
  return (
    <section
      id="section-contact"
      className="page contact-section bg-dark text-white bg-image"
      style={{ backgroundImage: `url(${map})` }}
    >
      {/* Element cover */}
      <div className="cover" />
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="col-inner">
              <h2>
                <OutboundLink href="https://kartgames.ch">
                  <div
                    className="btn btn-danger btn-rounded btn-lg"
                    style={{ fontSize: "2em" }}
                  >
                    <i className="fas fa-forward" />
                    {"  "}kartgames.ch{"  "}
                  </div>
                </OutboundLink>
              </h2>
            </div>
          </div>

          {/* /.col */}
        </div>{" "}
        {/* /.row */}
      </div>{" "}
      {/* /.container */}
    </section>
  )
}
