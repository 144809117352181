import React from "react"
import config from "../../config"

export default function News() {
  const showNews = config.news1 || config.news2
  return (
    showNews && (
      <>
        <div id={config.newsState} className="bg-white-caption text-dark">
          {config.news1}
        </div>
        <div id={config.newsState} className="text-white">
          {config.news2}
        </div>
      </>
    )
  )
}
