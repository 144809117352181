import React from "react"
import intessologo from "../assets/img/intesso-white.svg"
import egelmairlogo from "../assets/img/egelmair-logo.png"
import { OutboundLink } from "gatsby-plugin-gtag"

export default function Footer() {
  return (
    <footer id="footer" className="bg-dark text-white">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="copyright small">
              From
              <OutboundLink href="http://intesso.com" target="blank">
                <img
                  src={intessologo}
                  alt="intesso engineering"
                  height="33px"
                />
              </OutboundLink>
              <br />
            </div>
          </div>
          {/* /.col */}
          <div className="col-lg-6">
            <div className="copyright small">
              Media by
              <OutboundLink
                className="brand"
                href="http://www.egelmair.ch"
                target="blank"
              >
                <img
                  src={egelmairlogo}
                  alt="Egelmair Fotografie"
                  height="33px"
                />
              </OutboundLink>
            </div>
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
        <br />
        <br />
        <div className="row">
          <div className="col-lg-12">Copyright © All Rights Reserved</div>
        </div>
      </div>
    </footer>
  )
}
