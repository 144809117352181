import React from "react"
import { DateTime } from "luxon"
// const { DateTime } = require('luxon')

const dates = [
  "2022-04-21",
  "2022-05-05",
  "2022-05-19",
  "2022-06-02",
  "2022-06-09",
]

export function remainingDates() {
  const today = DateTime.now().startOf("day")
  return dates
    .map(dateString => DateTime.fromISO(dateString))
    .filter(date => date >= today)
    .map(date => date.setLocale("de").toFormat("dd.LLL"))
}

export function days() {
  return `Nur noch ${remainingDates().length} mal :-(`
}

export function datesFormatted() {
  return remainingDates().join(", ")
}

export default function Countdown() {
  return (
    <>
      <div className="bg-white-caption text-dark">{days()}</div>
      <div className="text-white">{datesFormatted()}</div>
    </>
  )
}
