import React from "react"
import introhigh from "../assets/img/intro/intro-high.jpg"
import News from "./News"
import { OutboundLink } from "gatsby-plugin-gtag"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Countdown from "./countdown"
import Redirect from "./Redirect"

export default function Intro(props) {
  return (
    <section id="section-intro" className="intro-parallax full-height">
      {/* Element background image (parallax) */}
      <div
        className="full-cover bg-image"
        style={{ backgroundImage: `url(${introhigh})` }}
      />
      {/* Element cover */}
      <div className="cover" />
      {/* Begin intro caption */}
      <div className="intro-caption text-white">
        <h1>
          <span className="text-white">{props.title}</span>
          <br />
          <span className="bg-main-caption text-white">{props.subtitle}</span>
          <br />
          <br />
          <News />
          {props.countdown && <Countdown />}
          <Redirect />
        </h1>
        <h2>
          <OutboundLink href="https://kartgames.ch">
            <div
              className="btn btn-danger btn-rounded btn-lg"
              style={{ fontSize: "2em" }}
            >
              <i className="fas fa-forward" />
              {"  "}kartgames.ch{"  "}
            </div>
          </OutboundLink>
        </h2>
      </div>
      {/* End intro caption */}
      {/* Begin made with love :) */}
      <div className="made-with-love hide-from-xs">
        <OutboundLink href="http://intesso.com" target="blank">
          <p className="text-white">
            made with{" "}
            <span className="text-red">
              <i className="far fa-heart" />
            </span>
          </p>
        </OutboundLink>
      </div>
      {/* End made with love */}
      {/* Scroll down arrow */}
      <AnchorLink
        className="scroll-down-arrow page-scroll text-center"
        to={props.section}
      >
        <span className="text-white">
          <i className="fas fa-arrow-down" />
        </span>
      </AnchorLink>
    </section>
  )
}
